import { Container, Row, Col } from "react-bootstrap"
import { FaTiktok, FaLinkedin, FaGithub } from "react-icons/fa";

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                        <a href="https://github.com/IanHinden"><FaGithub /></a>
                        <a href="https://www.linkedin.com/in/ian-hinden-78148970/"><FaLinkedin /></a>
                        <a href="https://www.tiktok.com/@hatefilledbox"><FaTiktok /></a>
                        </div>
                        <p>Copyright 2023. All Rights Reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}