import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import OrphanLogo from '../Assets/Orphan Logo.PNG'
import "react-multi-carousel/lib/styles.css";

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };

    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>
                                Projects
                            </h2>
                            <p>Projects of mine have been on MTV News, ladBIBLE, HypeBeast, Yahoo and more.<br></br>Enjoy!</p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">
                                    <a href="https://hypebeast.com/2022/6/sony-morbius-third-theatrical-release-petition"><img src={"https://www.denofgeek.com/wp-content/uploads/2022/03/Jared-Leto-in-Morbius-Review.jpeg?resize=768%2C432"} alt="Morbius" /></a>
                                    <h4>Morbius Petition</h4>
                                    <h5>A Change.org petition that went viral</h5>
                                </div>
                                <div className="item">
                                    <a href="https://vimeo.com/32779180"><img src={OrphanLogo} alt="Orphan Logo" /></a>
                                    <h4>Orphan</h4>
                                    <h5>An alternate reality game reported on by MTV</h5>
                                </div>
                                <div className="item">
                                    <a href="http://www.pyramidschemecosmetics.com"><img src={"http://www.pyramidschemecosmetics.com/images/BigBox.jpg"} alt="Make Up Box" /></a>
                                    <h4>Pyramid Scheme Cosmetics</h4>
                                    <h5>A short film and website</h5>
                                </div>
                                <div className="item">
                                    <a href="http://www.thegarbage.org"><img src={"http://www.thegarbage.org/images/polaroidian.png"} alt="Polaroid of Jacket" /></a>
                                    <h4>The Garb Age Collection</h4>
                                    <h5>Fashion to die for...</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    ) 
}